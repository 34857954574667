import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import FailureMenu from './_menu.js'

const Failure2016TourPage = ({ data }) => (
  <Layout bgClass="clients-failure">
    <FailureMenu pageName="2016_tour" />
    <div className="textPageDetails">
      <p>
        The poster series for Failure’s 2016 tour to celebrate the 20th anniversary of their album, <i>Fantastic Planet</i>.  The band required 18 different posters to echo the retro sci-fi vibe of their classic album.
      </p>
    </div>
    <div className="gallery-container">
    <NakaLightbox images={data.allFile.edges} />
    </div>
  </Layout>
)

Failure2016TourPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Failure2016TourPage

export const pageQuery = graphql`
  query Failure2016TourPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/failure/2016_tour/"}}
    	sort: {fields: name}
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
